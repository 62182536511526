var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1"},[_c('v-snackbar',{attrs:{"transition":"scroll-y-transition","top":"","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Design saved successfully ")]),_c('Navbar'),_c('v-toolbar',{staticClass:"transparent",attrs:{"elevation":"1"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","x-large":""}},[_c('v-icon',[_vm._v("mdi-image-edit-outline")]),_c('span',{staticClass:"ml-3"},[_vm._v("Customize Graphic")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"bg-gradient text-capitalize",attrs:{"dark":"","depressed":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-10",attrs:{"cols":"8"}},[_c('v-card',{ref:"canvasCard",staticClass:"d-flex align-center justify-center",attrs:{"height":"100%","width":"100%","flat":""}},[_c('v-card',{ref:"container",staticClass:"mainCanvas transparent",attrs:{"id":"capture"}},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize,"id":"theCanvas"}},[_c('v-layer',{ref:"layer"},[_c('v-image',{attrs:{"config":{
                                        image: _vm.image,
                                        width: _vm.bgWidth,
                                        height: _vm.bgHeight
                                    }}}),(_vm.showCompany)?_c('v-text',{ref:"text",attrs:{"config":{
                                        x: 120,
                                        y: 20,
                                        fontFamily: 'Calibri',
                                        fontSize: _vm.brandFontSize,
                                        text: _vm.brandText,
                                        fill: _vm.brandTextColor,
                                        draggable: true,
                                        fill: _vm.isDragging ? 'red' : _vm.brandTextColor
                                    }},on:{"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd}}):_vm._e(),(_vm.showRera)?_c('v-text',{ref:"text",attrs:{"config":{
                                        x: 300,
                                        y: 10,
                                        fontFamily: 'Calibri',
                                        fontSize: _vm.reraFontSize,
                                        text: _vm.reraText,
                                        fill: _vm.reraTextColor,
                                        draggable: true,
                                        fill: _vm.isDragging ? 'red' : _vm.reraTextColor
                                    }},on:{"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd}}):_vm._e()],1),(_vm.showPhone)?_c('v-layer',{attrs:{"config":{draggable: true,}}},[_c('v-image',{attrs:{"config":{
                                        image: _vm.phoneIcon,
                                        x: _vm.bgWidth/26,
                                        y: _vm.bgHeight/1.35,
                                        width: 16,
                                        height: 16
                                    }}}),_c('v-text',{ref:"text",attrs:{"config":{
                                        x: _vm.bgWidth/12,
                                        y: _vm.bgHeight/1.34,
                                        fontFamily: 'Lato',
                                        fontSize: _vm.defaultSize,
                                        text: _vm.contact,
                                        fill: _vm.contactColor,
                                        fill: _vm.isDragging ? 'red' : _vm.contactColor
                                    }}})],1):_vm._e(),(_vm.showEmail)?_c('v-layer',{attrs:{"config":{draggable: true,}}},[_c('v-image',{attrs:{"config":{
                                        image: _vm.emailIcon,
                                        x: _vm.bgWidth/26,
                                        y: _vm.bgHeight/1.24,
                                        width: 16,
                                        height: 16
                                    }}}),_c('v-text',{ref:"text",attrs:{"config":{
                                        x: _vm.bgWidth/12,
                                        y: _vm.bgHeight/1.234,
                                        fontFamily: 'Lato',
                                        fontSize: _vm.defaultSize,
                                        text: _vm.email,
                                        fill: _vm.emailColor,
                                        fill: _vm.isDragging ? 'red' : _vm.emailColor
                                    }}})],1):_vm._e(),(_vm.showWebsite)?_c('v-layer',{attrs:{"config":{draggable: true,}}},[_c('v-image',{attrs:{"config":{
                                        image: _vm.websiteIcon,
                                        x: _vm.bgWidth/26,
                                        y: _vm.bgHeight/1.14,
                                        width: 16,
                                        height: 16
                                    }}}),_c('v-text',{ref:"text",attrs:{"config":{
                                        x: _vm.bgWidth/12,
                                        y: _vm.bgHeight/1.134,
                                        fontFamily: 'Lato',
                                        fontSize: _vm.defaultSize,
                                        text: _vm.website,
                                        fill: _vm.websiteColor,
                                        fill: _vm.isDragging ? 'red' : _vm.websiteColor
                                    }}})],1):_vm._e(),_c('v-layer',[(_vm.showLogo)?_c('v-image',{attrs:{"config":{
                                        image: _vm.logo,
                                        draggable: true,
                                        width: _vm.logoWidth,
                                        height: _vm.logoHeight
                                    }},on:{"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{staticClass:"py-10",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"overflow-y-auto transparent",attrs:{"height":"75vh","flat":""}},[_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5 blue-grey lighten-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showRera),callback:function ($$v) {_vm.showRera=$$v},expression:"showRera"}}),_c('span',{},[_vm._v("Rera Number")])],1),_c('div',{staticClass:"d-flex align-center px-5 py-3"},[_c('span',[_vm._v("Size")]),_c('v-spacer'),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.reraFontSize -= 2}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.reraFontSize += 2}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require("../../assets/img/color.png"),"width":"26","height":"26","contain":""}},'v-img',attrs,false),on))]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('v-color-picker',{attrs:{"hide-inputs":"","canvas-height":"80px"},model:{value:(_vm.reraTextColor),callback:function ($$v) {_vm.reraTextColor=$$v},expression:"reraTextColor"}})],1)],1)],1)]),_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5 blue-grey lighten-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showCompany),callback:function ($$v) {_vm.showCompany=$$v},expression:"showCompany"}}),_c('span',{},[_vm._v("Company Name")])],1),_c('div',{staticClass:"d-flex align-center px-5 py-3"},[_c('span',[_vm._v("Size")]),_c('v-spacer'),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.brandFontSize -= 2}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.brandFontSize += 2}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require("../../assets/img/color.png"),"width":"26","height":"26","contain":""}},'v-img',attrs,false),on))]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('v-color-picker',{attrs:{"hide-inputs":"","canvas-height":"80px"},model:{value:(_vm.brandTextColor),callback:function ($$v) {_vm.brandTextColor=$$v},expression:"brandTextColor"}})],1)],1)],1)]),_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5 blue-grey lighten-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showLogo),callback:function ($$v) {_vm.showLogo=$$v},expression:"showLogo"}}),_c('span',{},[_vm._v("Logo")])],1),_c('div',{staticClass:"d-flex align-center px-5 py-3"},[_c('span',[_vm._v("Size")]),_c('v-spacer'),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.logoWidth -= 50}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('v-btn',{staticClass:"white rounded",attrs:{"x-small":"","fab":"","tile":"","elevation":"1"},on:{"click":function($event){_vm.logoWidth += 50}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]),_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showEmail),callback:function ($$v) {_vm.showEmail=$$v},expression:"showEmail"}}),_c('span',{},[_vm._v("Email")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require("../../assets/img/color.png"),"width":"26","height":"26","contain":""}},'v-img',attrs,false),on))]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('v-color-picker',{attrs:{"hide-inputs":"","canvas-height":"80px"},model:{value:(_vm.emailColor),callback:function ($$v) {_vm.emailColor=$$v},expression:"emailColor"}})],1)],1)],1)]),_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showWebsite),callback:function ($$v) {_vm.showWebsite=$$v},expression:"showWebsite"}}),_c('span',{},[_vm._v("Website")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require("../../assets/img/color.png"),"width":"26","height":"26","contain":""}},'v-img',attrs,false),on))]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('v-color-picker',{attrs:{"hide-inputs":"","canvas-height":"80px"},model:{value:(_vm.websiteColor),callback:function ($$v) {_vm.websiteColor=$$v},expression:"websiteColor"}})],1)],1)],1)]),_c('v-card',{staticClass:"rounded-lg mb-4"},[_c('div',{staticClass:"font-weight-bold d-flex align-center px-5"},[_c('v-switch',{attrs:{"dense":"","color":"teal darken-2"},model:{value:(_vm.showPhone),callback:function ($$v) {_vm.showPhone=$$v},expression:"showPhone"}}),_c('span',{},[_vm._v("Contact")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require("../../assets/img/color.png"),"width":"26","height":"26","contain":""}},'v-img',attrs,false),on))]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('v-color-picker',{attrs:{"hide-inputs":"","canvas-height":"80px"},model:{value:(_vm.contactColor),callback:function ($$v) {_vm.contactColor=$$v},expression:"contactColor"}})],1)],1)],1)])],1)],1)],1)],1),_c('div',{staticClass:"susccess-msg-div"},[(_vm.downloadProgress)?_c('div',{staticClass:"loaderScreen"},[_vm._m(0)]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.savedDialog),callback:function ($$v) {_vm.savedDialog=$$v},expression:"savedDialog"}},[_c('v-card',{staticClass:"text-center pa-4"},[_c('v-btn',{attrs:{"x-large":"","icon":"","outlined":"","color":"green"}},[_c('v-icon',{attrs:{"size":"42px"}},[_vm._v("mdi-check")])],1),_c('div',{staticClass:"title"},[_vm._v("Downloaded!")]),_c('v-card-subtitle',[_vm._v("Graphic saved successfully")]),_c('v-btn',{attrs:{"color":"green darken-1","depressed":"","rounded-xl":"","dark":""},on:{"click":function($event){_vm.savedDialog = false}}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative h-full"},[_c('div',{staticClass:"logoText"},[_vm._v("Creating your design...")]),_c('div',{staticClass:"loader"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})])])}]

export { render, staticRenderFns }